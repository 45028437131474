(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['exports', 'jquery'], factory);
    } else if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
        // CommonJS
        factory(exports, require('jquery'));
    } else {
        // Browser globals
        factory((root.commonJsStrict = {}), root.jQuery);
    }
}(this, function (exports, $) {



    $(function () {
        $('.js-lazyYT').lazyYT('AIzaSyBILUTvkadH8z1DFlag9XDlPGTw5zhEgCQ');


        $('.easy-footnote').on('a', 'click', function(e) {
            e.preventDefault();
        });

        var $mastHeadWrap = $('#masthead-wrap');
        var $menuOverlayInner = $mastHeadWrap.find('.menu-overlay-inner');
        var $window = $(window);
        var $body = $('body');
        var lastScrollTop = 0;

        // function animateMenuOverlay() {
        //     $menuOverlayInner.animate({
        //         'transform': 'translateY(-50px)',
        //         '-webkit-transform': 'translateY(-50px)',
        //         'opacity': 1
        //     }, 300);
        // }


        $mastHeadWrap.find('.header-alt').append($('#wtr-progress'));

        $mastHeadWrap.on('click', '.main-nav-toggle', function(e) {
            e.preventDefault();
            if($body.hasClass('is-nav-open')) {
                $body.removeClass('is-nav-open is-search-open trigger-animate');

                if($window.scrollTop() <= 400) {
                    $body.removeClass('header-alt-opened');
                }
            }
            else {
                $body.addClass('is-nav-open');
                $body.removeClass('is-search-open trigger-animate');

                setTimeout(function () {
                    $body.addClass('trigger-animate');
                }, 5);
            }
            
        });

        $mastHeadWrap.on('click', '.icon-search', function(e) {
            e.preventDefault();
            $body.addClass('is-nav-open is-search-open');
            setTimeout(function () {
                $body.addClass('trigger-animate');
            }, 5);
        });

        var headerAltOpened = false;
        $window.on('scroll', function(e) {
            var scrollTop = $window.scrollTop();

            if($body.hasClass('is-nav-open')) {
                return;
            }

            if (scrollTop > 400) {
                if(headerAltOpened) {
                   return; 
                }
                headerAltOpened = true;
                $body.addClass('header-alt-opened');
            } else {
                if(!headerAltOpened) {
                    return;
                }
                headerAltOpened = false;
                $body.removeClass('header-alt-opened');
            }
        });


        var $apssShareDiv = $('.apss-share-wrapper-div');

        if($apssShareDiv.length > 0) {
            $apssShareDiv.append('<div class="apss-single-icon" style="padding-top: 10px;"><a data-pocket-label="pocket" data-pocket-count="none" class="pocket-btn" data-lang="en"></a></div>');
            !function(d,i){if(!d.getElementById(i)){var j=d.createElement("script");j.id=i;j.src="https://widgets.getpocket.com/v1/j/btn.js?v=1";var w=d.getElementById(i);d.body.appendChild(j);}}(document,"pocket-btn-js");
        }


    });


}));